.fingerContainer {
  height: 420px;
  margin: 21px auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.handsContainer {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    #009bdd,
    #0790d4,
    #1086cb,
    #177bc2,
    #1d71b8
  );
}

.imageHands {
  position: absolute;
  top: 0;
  max-width: 100%;
  max-height: 100%;
}

.checkboxHands {
  position: absolute;
  font: normal normal bold 10px/13px Muli;
  color: #ffffff;
}

.checkboxCustom {
  color: #ffffff;
  padding: 0px;
}

.pinky-right {
  right: 88%;
  bottom: 24%;
}
.ringFinger-right {
  right: 84%;
  bottom: 10%;
}
.middleFinger-right {
  right: 76%;
  bottom: 2%;
}
.indexFinger-right {
  right: 69%;
  bottom: 3%;
}
.thumb-right {
  right: 51%;
  bottom: 33%;
}

.pinky-left {
  left: 88%;
  bottom: 24%;
}
.ringFinger-left {
  left: 84%;
  bottom: 8%;
}
.middleFinger-left {
  left: 76%;
  bottom: 0%;
}
.indexFinger-left {
  left: 69%;
  bottom: 2%;
}
.thumb-left {
  left: 51%;
  bottom: 33%;
}

/* Media query */
@media (max-width: 361px) {
  
  .pinky-right-mobile {
    right: 71% !important;
    bottom: 32% !important;
  }
  .ringFinger-right-mobile {
    right: 66% !important;
    bottom: 20% !important;
  }
  .middleFinger-right-mobile {
    right: 50% !important;
    bottom: 11% !important;
  }
  .indexFinger-right-mobile {
    right: 36% !important;
    bottom: 15% !important;
  }
  .thumb-right-mobile {
    right: 2% !important;
    bottom: 41% !important;
  }
  
  .pinky-left-mobile {
    left: 71% !important;
    bottom: 32% !important;
  }
  .ringFinger-left-mobile {
    left: 66% !important;
    bottom: 20% !important;
  }
  .middleFinger-left-mobile {
    left: 50% !important;
    bottom: 12% !important;
  }
  .indexFinger-left-mobile {
    left: 36% !important;
    bottom: 16% !important;
  }
  .thumb-left-mobile {
    left: 2% !important;
    bottom: 40% !important;
  }
  
}
/* mobile fingers  */

.pinky-right-mobile {
  right: 75%;
  bottom: 24%;
}
.ringFinger-right-mobile {
  right: 66%;
  bottom: 10%;
}
.middleFinger-right-mobile {
  right: 50%;
  bottom: 2%;
}
.indexFinger-right-mobile {
  right: 36%;
  bottom: 3%;
}
.thumb-right-mobile {
  right: 2%;
  bottom: 33%;
}

.pinky-left-mobile {
  left: 75%;
  bottom: 24%;
}
.ringFinger-left-mobile {
  left: 66%;
  bottom: 8%;
}
.middleFinger-left-mobile {
  left: 50%;
  bottom: 0%;
}
.indexFinger-left-mobile {
  left: 36%;
  bottom: 2%;
}
.thumb-left-mobile {
  left: 2%;
  bottom: 33%;
}

.MuiAccordion-root.Mui-expanded:last-child {
  margin-bottom: 20px !important;
}
@media (min-width: 1250px) {
  .fingerContainer {
    width: 726px;
  }
}
