
.progress {
	background-color: #d6d6d6;
	border-radius: 3px;
	position: relative;
	margin: 10px 0;
	height: 30px;
	width: auto;
}

.progress-done {
	background: linear-gradient(to left, #fa5a00, #fa5a00);
  /* box-shadow: 0 3px 3px -5px #FA5A00, 0 2px 5px #FA5A00; */
  border-radius: 3px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: initial;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;

}

.progress-colors {
	/* box-shadow: 0 3px 3px -5px #FA5A00, 0 2px 5px #FA5A00; */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: initial;
}

#green{
	border-radius: 30px 0px 0px 30px;
}

#red{
	border-radius: 0px 30px 30px 0px;
}
.chart-bar {
  position: relative;
  margin: 3px 0;
  height: 25px;
  width: 100%;
}

.chart-bar-done {
  background: linear-gradient(to left, #fa5a00, #fa5a00);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: initial;
}
