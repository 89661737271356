.marquee {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee .slide {
  /* margin: 0 !important; */
  display: inline-block;
  padding-left: 70%;
  animation: marquee 15s linear infinite;
}
.marquee .hold {
  margin: 0;
  display: inline-block;
  text-align: left;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
