@import url(https://use.typekit.net/mpw5dkx.css);
@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;1,600;1,900&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.progress {
	background-color: #d6d6d6;
	border-radius: 3px;
	position: relative;
	margin: 10px 0;
	height: 30px;
	width: auto;
}

.progress-done {
	background: linear-gradient(to left, #fa5a00, #fa5a00);
  /* box-shadow: 0 3px 3px -5px #FA5A00, 0 2px 5px #FA5A00; */
  border-radius: 3px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  text-align: initial;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;

}

.progress-colors {
	/* box-shadow: 0 3px 3px -5px #FA5A00, 0 2px 5px #FA5A00; */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  text-align: initial;
}

#green{
	border-radius: 30px 0px 0px 30px;
}

#red{
	border-radius: 0px 30px 30px 0px;
}
.chart-bar {
  position: relative;
  margin: 3px 0;
  height: 25px;
  width: 100%;
}

.chart-bar-done {
  background: linear-gradient(to left, #fa5a00, #fa5a00);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  text-align: initial;
}



.toggle-button-cover
{
    display: table-cell;
    position: relative;
    width: 200px;
    height: 50px;
    box-sizing: border-box;
}

.button-cover
{

    background-color: #fff;
  /*  box-shadow: 0 10px 20px -8px #c5d6d6; */
    border-radius: 4px;
}

.button-cover:before
{

    position: absolute;
    right: 0;
    bottom: 0;
    color: #d7e3e3;
    font-size: 12px;
    line-height: 1;
    padding: 1px;
}

.button-cover, .knobs, .layer
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.button
{
    position: relative;
    top: 50%;
    width: 122px;
    height: 40px;
    margin: -20px auto 0 auto;
    overflow: hidden;
}

.button.r, .button.r .layer
{
    border-radius: 100px;
}

.button.b2
{
    border-radius: 2px;
}

.checkbox
{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.knobs
{
    z-index: 2;
}

.layer
{
    width: 100%;
    background-color: #F5F5F5;
    border-radius: 10px;
    transition: 0.3s ease all;
    z-index: 1;
}



/* Button 10 */
#button-10 .knobs:before, #button-10 .knobs:after, #button-10 .knobs span
{
    position: absolute;
    width: 50px;
    height: 20px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    border-radius: 10px;
    transition: 0.2s ease-in all;
}

#button-10 .knobs:before
{
    content: '';
    left: 4px;
    background-color: #03A9F4;
}

#button-10 .knobs:after
{
    content: 'Específico';
    right: 4px;
    color: #909090;
}

#button-10 .knobs span
{
    display: inline-block;
    left: 4px;
    color: #fff;
    z-index: 1;
}

#button-10 .checkbox:checked + .knobs span
{
    color: #909090;
}

#button-10 .checkbox:checked + .knobs:before
{
    left: 62px;
    background-color: #03A9F4;
}

#button-10 .checkbox:checked + .knobs:after
{
    color: #fff;
}

#button-10 .checkbox:checked ~ .layer
{
    background-color: #F5F5F5;
}




#ytd-url {
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 2px 2px;
  margin: 20px;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  background-color: #143240;
  border-radius: 4px;
  box-shadow: 0 10px 20px -5px rgba(20, 50, 64, 0.76);
}
#toolbar-headerSection {
  top: 166px;
  left: 357px;
  width: 580px;
  height: 30px;
  border: none;
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 1fr 2fr 2fr 2fr;
  grid-auto-flow: row;
  align-content: center;
  padding-right: 0.6em;
  text-align: center;
}
#toolbar-footerSection {
  top: 166px;
  left: 357px;
  width: 580px;
  height: 30px;
  border: none;
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 1fr 2fr 2fr 2fr;
  grid-auto-flow: row;
  align-content: center;
  padding-right: 0.6em;
  text-align: center;
}
.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
}

.custom-buttons {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  /* float: left; */
  height: 24px;
  padding: 3px 5px;
  width: 28px;
}

.custom {
  border-color: transparent;
  background-color: transparent;
}

/* buttons section*/

.ql-icon {
  margin-top: 10%;
  background-repeat: no-repeat !important;
}

.ql-color {
  background-image: url(/static/media/gridIcon.d4bb7c3b.svg) !important;
  background-size: 14px 14px !important;
  border-color: transparent;
  width: 18px;
}
.ql-italic {
  background: transparent 0% 0% no-repeat padding-box;
}

.ql-underline {
  background: transparent 0% 0% no-repeat padding-box;
}

.ql-left {
  background: transparent 0% 0% no-repeat padding-box;
}
.ql-center {
  background: transparent 0% 0% no-repeat padding-box;
}
.ql-right {
  background: transparent 0% 0% no-repeat padding-box;
}

.size-select {
  top: 172px;
  left: 417px;
  width: 45px;
  height: 18px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  opacity: 1;
}

.list-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 2em;
  margin-left: 1em;
}

.grid-container {
  display: grid;
  grid-template-columns: 9fr 3fr;
  background: #efefef 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 4px #00000033;
  border-radius: 5px;
  opacity: 1;
  margin-left: 40px;
  margin-right: 40px;
}

.size-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
}

.style-section {
  display: flex;
  flex-direction: row;
  margin-right: 1.5em;
  align-self: center;
}

.align-section {
  display: flex;
  flex-direction: row;
  margin-right: 1.5em;
}

.list-section {
  display: flex;
  flex-direction: row;
  margin-right: 1.5em;
}

.indent-section {
  display: flex;
  flex-direction: row;
  margin-right: 1.5em;
}

.ql-select-size {
  width: 118px !important;
}

.ql-snow .ql-picker.ql-size {
  width: 118px;
}

.custom-hr {
  border: none;
  border-left: 1px solid hsla(200, 10%, 50%, 100);
  height: 10px;
  width: 1px;
}

.tools-container {
  display: grid;
  grid-template-columns: 10px 70px 30px 30px 30px 30px;
}

.icon-names {
  background-image: url("/static/media/Icon awesome-user-alt.34fdfad7.svg") !important;
  background-size: 15px 15px !important;
}
.icon-surnames {
  background-image: url("/static/media/Grupo 3595 (1).4027de0d.svg") !important;
  background-size: 17px 17px !important;
}
.icon-address {
  background-image: url("/static/media/Icon awesome-address-card.6e9c17c8.svg") !important;
  background-size: 18px 18px !important;
}

.icon-color {
  background-image: url(/static/media/fill-drip-icon.93288de7.svg) !important;
  background-size: 18px 18px !important;
}

#footer-hr {
  border: 0 none;
  border-top: 2px dashed #00000033;
  background: none;
  height: 0;
}

#footer-bold {
  border: 0 none;
  border-bottom: 3px solid #000000;
  max-width: '80%';
  background: none;
  height: 0;
  margin: 0 auto;

}

#header-hr {
  border: 0 none;
  border-top: 2px dashed #00000033;
  background: none;
  height: 0;
}

select {
  -webkit-appearance: none;
  appearance: none;
  text-indent: 1px;
}

/* width */
.ql-editor::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
.ql-editor::-webkit-scrollbar-thumb {
  background: #aaaaaa66; 
}

/* Handle on hover */
.ql-editor::-webkit-scrollbar-thumb:hover {
  background: #b7b7b7; 
}

/* Set content for font-families */
.ql-font-arial,
.ql-font span[data-value="arial"]::before {
  font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value="comic-sans"]::before {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
  font-family: Georgia, serif;
}
.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}
.ql-font-Verdana,
.ql-font span[data-value="Verdana"]::before {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.ql-font-Ubuntu,
.ql-font span[data-label="Ubuntu"]::before {
  font-family: "Ubuntu";
}
.ql-font-Montserrat,
.ql-font span[data-label="Montserrat"]::before {
  font-family: 'Montserrat', sans-serif;
}
.ql-font-Inconsolata,
.ql-font span[data-label="Inconsolata"]::before {
  font-family: 'Inconsolata', monospace;
}

/* Set content for sizes */
.ql-size-extra-small,
.ql-size span[data-value="extra-small"]::before {
  font-size: 13px !important;
}
.ql-size-small,
.ql-size span[data-value="small"]::before {
  font-size: 14px !important;
}
.ql-size-medium,
.ql-size span[data-value="medium"]::before {
  font-size: 18px !important;
}
.ql-size-large,
.ql-size span[data-value="large"]::before {
  font-size: 20px !important;
}




.marquee {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee .slide {
  /* margin: 0 !important; */
  display: inline-block;
  padding-left: 70%;
  -webkit-animation: marquee 15s linear infinite;
          animation: marquee 15s linear infinite;
}
.marquee .hold {
  margin: 0;
  display: inline-block;
  text-align: left;
}
@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}
@keyframes marquee {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}

.fingerContainer {
  height: 420px;
  margin: 21px auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.handsContainer {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    #009bdd,
    #0790d4,
    #1086cb,
    #177bc2,
    #1d71b8
  );
}

.imageHands {
  position: absolute;
  top: 0;
  max-width: 100%;
  max-height: 100%;
}

.checkboxHands {
  position: absolute;
  font: normal normal bold 10px/13px Muli;
  color: #ffffff;
}

.checkboxCustom {
  color: #ffffff;
  padding: 0px;
}

.pinky-right {
  right: 88%;
  bottom: 24%;
}
.ringFinger-right {
  right: 84%;
  bottom: 10%;
}
.middleFinger-right {
  right: 76%;
  bottom: 2%;
}
.indexFinger-right {
  right: 69%;
  bottom: 3%;
}
.thumb-right {
  right: 51%;
  bottom: 33%;
}

.pinky-left {
  left: 88%;
  bottom: 24%;
}
.ringFinger-left {
  left: 84%;
  bottom: 8%;
}
.middleFinger-left {
  left: 76%;
  bottom: 0%;
}
.indexFinger-left {
  left: 69%;
  bottom: 2%;
}
.thumb-left {
  left: 51%;
  bottom: 33%;
}

/* Media query */
@media (max-width: 361px) {
  
  .pinky-right-mobile {
    right: 71% !important;
    bottom: 32% !important;
  }
  .ringFinger-right-mobile {
    right: 66% !important;
    bottom: 20% !important;
  }
  .middleFinger-right-mobile {
    right: 50% !important;
    bottom: 11% !important;
  }
  .indexFinger-right-mobile {
    right: 36% !important;
    bottom: 15% !important;
  }
  .thumb-right-mobile {
    right: 2% !important;
    bottom: 41% !important;
  }
  
  .pinky-left-mobile {
    left: 71% !important;
    bottom: 32% !important;
  }
  .ringFinger-left-mobile {
    left: 66% !important;
    bottom: 20% !important;
  }
  .middleFinger-left-mobile {
    left: 50% !important;
    bottom: 12% !important;
  }
  .indexFinger-left-mobile {
    left: 36% !important;
    bottom: 16% !important;
  }
  .thumb-left-mobile {
    left: 2% !important;
    bottom: 40% !important;
  }
  
}
/* mobile fingers  */

.pinky-right-mobile {
  right: 75%;
  bottom: 24%;
}
.ringFinger-right-mobile {
  right: 66%;
  bottom: 10%;
}
.middleFinger-right-mobile {
  right: 50%;
  bottom: 2%;
}
.indexFinger-right-mobile {
  right: 36%;
  bottom: 3%;
}
.thumb-right-mobile {
  right: 2%;
  bottom: 33%;
}

.pinky-left-mobile {
  left: 75%;
  bottom: 24%;
}
.ringFinger-left-mobile {
  left: 66%;
  bottom: 8%;
}
.middleFinger-left-mobile {
  left: 50%;
  bottom: 0%;
}
.indexFinger-left-mobile {
  left: 36%;
  bottom: 2%;
}
.thumb-left-mobile {
  left: 2%;
  bottom: 33%;
}

.MuiAccordion-root.Mui-expanded:last-child {
  margin-bottom: 20px !important;
}
@media (min-width: 1250px) {
  .fingerContainer {
    width: 726px;
  }
}

