

.toggle-button-cover
{
    display: table-cell;
    position: relative;
    width: 200px;
    height: 50px;
    box-sizing: border-box;
}

.button-cover
{

    background-color: #fff;
  /*  box-shadow: 0 10px 20px -8px #c5d6d6; */
    border-radius: 4px;
}

.button-cover:before
{

    position: absolute;
    right: 0;
    bottom: 0;
    color: #d7e3e3;
    font-size: 12px;
    line-height: 1;
    padding: 1px;
}

.button-cover, .knobs, .layer
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.button
{
    position: relative;
    top: 50%;
    width: 122px;
    height: 40px;
    margin: -20px auto 0 auto;
    overflow: hidden;
}

.button.r, .button.r .layer
{
    border-radius: 100px;
}

.button.b2
{
    border-radius: 2px;
}

.checkbox
{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.knobs
{
    z-index: 2;
}

.layer
{
    width: 100%;
    background-color: #F5F5F5;
    border-radius: 10px;
    transition: 0.3s ease all;
    z-index: 1;
}



/* Button 10 */
#button-10 .knobs:before, #button-10 .knobs:after, #button-10 .knobs span
{
    position: absolute;
    width: 50px;
    height: 20px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    border-radius: 10px;
    transition: 0.2s ease-in all;
}

#button-10 .knobs:before
{
    content: '';
    left: 4px;
    background-color: #03A9F4;
}

#button-10 .knobs:after
{
    content: 'Específico';
    right: 4px;
    color: #909090;
}

#button-10 .knobs span
{
    display: inline-block;
    left: 4px;
    color: #fff;
    z-index: 1;
}

#button-10 .checkbox:checked + .knobs span
{
    color: #909090;
}

#button-10 .checkbox:checked + .knobs:before
{
    left: 62px;
    background-color: #03A9F4;
}

#button-10 .checkbox:checked + .knobs:after
{
    color: #fff;
}

#button-10 .checkbox:checked ~ .layer
{
    background-color: #F5F5F5;
}




#ytd-url {
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 2px 2px;
  margin: 20px;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  background-color: #143240;
  border-radius: 4px;
  box-shadow: 0 10px 20px -5px rgba(20, 50, 64, 0.76);
}